import { MDCTopAppBar } from '@material/top-app-bar';
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { MDCRipple } from '@material/ripple';
import { MDCSnackbar } from '@material/snackbar';

const topAppBarElement = document.querySelector('.mdc-top-app-bar');
const topAppBar = new MDCTopAppBar(topAppBarElement);

const textField = new MDCTextField(document.querySelector('.mdc-text-field'));
const notchedOutline = new MDCNotchedOutline(
	document.querySelector('.mdc-notched-outline'),
);

new MDCRipple(document.querySelector('.mdc-button'));

const snackbar__shortcodeError = new MDCSnackbar(
	document.querySelector('#snackbar--shortcode-error'),
);
